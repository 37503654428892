<template>
  <div class="p-5">
    <div class="card-header">
      <h3 class="card-title m-0">
        <label class="card-label fw-bolder">Mapa de Navegação</label>
      </h3>
    </div>
    <div class="mt-5">
      <el-tree
        v-if="tree.length > 0"
        style="max-width: 600px"
        :props="props"
        :key="treeKey"
        @node-click="handleNodeClick"
        :load="loadNode"
        lazy
        ref="teste"
      >
        <template #default="{ node }">
          <div>
            <i :class="`${getIconClass(node)} size-icon me-2`"></i>
            {{ node.label }}
          </div>
        </template>
      </el-tree>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, nextTick, watch } from "vue";
import {
  getTreeInitial,
  getVehicles,
  getPackages,
  getPackage,
  getPackageDetail,
} from "@/services/CardapioService";
import { useStore } from "vuex";
import useEmitter from "@/composables/Emmiter";

const tree = ref([]);
const teste = ref();
const treeKey = ref(0);
const storeTree = ref();
const openNodes = ref([]);
const store = useStore();
const emitter = useEmitter();
const concessionariaSelected = ref(0);
const itemSelected = ref({});
const grupoExibicaoSelect = ref('')

const props = ref({
  label: "name",
  isLeaf: (data) => data.isLeaf,
});

onMounted(async () => {
  concessionariaSelected.value =
  store.getters.concessionariaSelectedInfo.codConcessionaria;
  searchMenu();
});

emitter.on("filter-cardapio", async ({ codConcessionaria }) => {
  concessionariaSelected.value = codConcessionaria;
  console.log("filter");
  searchMenu();
});

emitter.on("update-tree", () => {
  restoreOpenNodes()
});

const searchMenu = async () => {
  tree.value = await getTreeInitial(concessionariaSelected.value);
  treeKey.value++;
};

const getIconClass = (nodeData) => {
  const { level, expanded, data } = nodeData;
  const { icon, iconExpanded, package: isPackage, service: isService } = data;

  const defaultIcon = "bi bi-file-earmark"; // default icon

  const iconRules = {
    1: () => (expanded ? iconExpanded : icon),
    2: () => (isService ? icon : expanded ? iconExpanded : icon),
    3: () => (expanded ? iconExpanded : icon),
    4: () => (isPackage ? (expanded ? iconExpanded : icon) : icon),
    5: () => icon,
  };

  return iconRules[level] ? iconRules[level]() : defaultIcon;
};

const handleNodeClick = (data) => {
  console.log('handleNodeClick',data);
  emitter.emit("select-item", data);
};

const loadNode = async (node, resolve) => {
  // console.log(node.data);
  // itemSelected.value = node.data;
  const codConcessionaria = concessionariaSelected.value;
  const handleError = (error) => {
    console.error("Erro ao carregar filhos:", error);
    resolve([]);
  };

  const setLeafNodes = (childNodes) => {
    childNodes.forEach((element) => {
      element.isLeaf = element.data.existChildren;
    });
  };

  try {
    if (node.level === 0) {
      resolve(tree.value);
      return;
    }

    let payload;
    let children;

    if (node.level === 1) {
      if (node.data.package) {
        payload = { codConcessionaria, codPackage: node.data.codPackage};
        children = await getPackageDetail(payload);
        resolve(children);
        setLeafNodes(node.childNodes);
      } else {
        payload = { codConcessionaria, family: node.data.name };
        children = await getVehicles(payload);
        resolve(children);
      }
    } else if (node.level === 2) {
      payload = { codConcessionaria, vehicles: node.data.family, family: node.data.name };
      children = await getPackages(payload);
      resolve(children);
    } else if (node.level === 3) {
      payload = {
        codConcessionaria,
        vehicles: node.data.vehicles,
        family: node.data.family,
        revisao: node.data.revisao,
        codigoFabrica: node.data.codigoFabrica,
      };

      children = await getPackage(payload);
      grupoExibicaoSelect.value = node.data.vehicles
      resolve(children);
      setLeafNodes(node.childNodes);
    } else if (node.level === 4 && node.data.package) {
      payload = { codConcessionaria, codPackage: node.data.codPackage , grupoExibicao: grupoExibicaoSelect.value};
      console.log('node',node.data)
      children = await getPackageDetail(payload);
      resolve(children);
      setLeafNodes(node.childNodes);
    }

    storeTree.value = node.store.nodesMap;
  } catch (error) {
    handleError(error);
  }
};

const saveOpenNodes = () => {
  openNodes.value = [];
  for (let node of Object.values(teste.value.store.nodesMap)) {
    if (node.expanded) {
      openNodes.value.push(node.data);
    }
  }

  console.log("openNodes.value", openNodes.value);
};

const expandNodeRecursive = async (node) => {
  // console.log()
  await new Promise((resolve) => loadNode(node, resolve));

  // if (openNodes.value.includes(node.data.name)) {
  //   node.expand();
  //   for (let childNode of node.childNodes) {

  // }
};

const restoreOpenNodes = async () => {
  saveOpenNodes(); // Save currently open nodes
  tree.value = await getTreeInitial(concessionariaSelected.value);

  treeKey.value++;

  // Aguarda o próximo ciclo de atualização do Vue
  await nextTick();

  // Função para encontrar um nó pelo nome
  const findNodeByName = (name) => {
    return Object.values(teste.value.store.nodesMap).find(
      (node) => node.data.name === name
    );
  };

  console.log("openNodes.value", openNodes.value);
  // Itera sobre os nós salvos e restaura os nós abertos
  for (let nodeName of openNodes.value) {
    let node = findNodeByName(nodeName.name);

    if (node) {
      node.expand(); // Expande o nó

      await expandNodeRecursive(node); // Expande recursivamente os nós filhos

      // Aguarda o próximo ciclo de atualização do Vue
      await nextTick();

      // Atualiza a referência do nó após expandir
      node = findNodeByName(nodeName.name);
    }
  }

  store.watch(
    () => store.getters.concessionariaSelectedInfo,
    (newCodConcessionaria) => {
      console.log(newCodConcessionaria);
    }
  );
};


</script>

<style>
.icon-success {
  color: #34bfa3 !important;
}

.size-icon {
  font-size: 18px;
}

.icon-danger {
  color: #f4516c !important;
}

.icon-primary {
  color: #5867dd !important;
}

.icon-primary-clear {
  color: #009ef7 !important;
}

.icon-warning {
  color: #ffb822 !important;
}

.icon-check {
  color: #00c5dc !important;
}
</style>
