import { GroupDetailView, GroupDisplay, ItemDetailRevision, PackageRevisionDetailView, PostGroupDetailView, PostItemDetailRevision, PostPackageRevisionDetailView, PostrevisionDetailView, PostRevisionItemDetailView, RevisionDetailView, RevisionItemDetailView, UpdateOrder } from "@/models/CardapioModel";
import ApiService from "./ApiService";

const getTreeInitial = async (codConcessionaria: number) => {
    const { data } = await ApiService.get(`/cardapio/treeInitial/${codConcessionaria}`);
    return data;
};

const getVehicles = async (postData) => {
    const { data } = await ApiService.post(`cardapio/veiculo`, postData);
    return data;
};

const getPackages = async (postData) => {
    const { data } = await ApiService.post(`cardapio/veiculo/pacotes`, postData);
    return data;
};

const getPackage = async (postData) => {
    const { data } = await ApiService.post(`cardapio/veiculo/pacote`, postData);
    return data;
};

const getPackageDetail = async (postData) => {
    const { data } = await ApiService.post(`cardapio/veiculo/pacote/detalhe`, postData);
    return data;
};

const itemDisplay = async (codConcessionaria: number) : Promise<GroupDisplay> => {
    const { data } = await ApiService.get(`cardapio/detalhe/item/${codConcessionaria}`);
    return data;
};

const updateOrder = async (postData : UpdateOrder) => {
    const { data } = await ApiService.post(`cardapio/detalhe/ordem`, postData as object);
    return data;
};

const groupDetailView = async (postData : PostGroupDetailView) : Promise<GroupDetailView> => {
    const { data } = await ApiService.post(`cardapio/detalhe/grupoExibicao`, postData as object);
    return data;
};

const revisionDetailView = async (postData : PostrevisionDetailView) : Promise<RevisionDetailView> => {
    const { data } = await ApiService.post(`cardapio/detalhe/revisao`, postData as object);
    return data;
}

const revisionItemDetailView = async (postData : PostRevisionItemDetailView) : Promise<RevisionItemDetailView> => {
    const { data } = await ApiService.post(`cardapio/detalhe/revisao/item`, postData as object);
    return data;
}

const itemRevisionDetailView = async (postData : PostItemDetailRevision) : Promise<ItemDetailRevision> => {
    const { data } = await ApiService.post(`cardapio/detalhe/revisao/item/servico`, postData as object);
    return data;
}

const packageRevisionDetailView = async (postData : PostPackageRevisionDetailView) : Promise<PackageRevisionDetailView> => {
    const { data } = await ApiService.post(`cardapio/detalhe/revisao/pacote`, postData as object);
    return data;
}

export {
    getTreeInitial,
    getVehicles,
    getPackages,
    getPackage,
    getPackageDetail,
    itemDisplay,
    updateOrder,
    groupDetailView,
    revisionDetailView,
    revisionItemDetailView,
    itemRevisionDetailView,
    packageRevisionDetailView
}