<template>
    <div>
        <div class="row">
          <div class="col-5" ref="treeCardapio">
           <TreeCardapio />
          </div>
          <div class="col-7" ref="edit">
           <Edit />
          </div>
        </div>
    </div>
</template>
<script setup>
import TreeCardapio from '@/views/cardapio/components/editCardapio/TreeCardapio'
import Edit from '@/views/cardapio/components/editCardapio/Edit'
import { onMounted, ref } from "vue";

const treeCardapio = ref(null);
const edit = ref(null);

onMounted(() => {

  // const treeHeight = treeCardapio.value.offsetHeight;
  //   const editHeight = edit.value.offsetHeight;
  //   const maxHeight = Math.max(treeHeight, editHeight);
  //   console.log('treeHeight',treeHeight)
  //   console.log('editHeight',editHeight)
  //   console.log('maxHeight',maxHeight)

  //   treeCardapio.value.style.height = `${maxHeight}px`;
  //   edit.value.style.height = `${maxHeight}px`;
});

</script>
<style>
.equal-height {
  overflow: hidden;
}
</style>
