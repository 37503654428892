<template>
  <div>
    Pneu
  </div>
</template>

<script setup>
</script>

<style scoped>
</style>
