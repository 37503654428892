<template>
  <div class="container-fluid" id="cardapio">
    <div class="d-flex">
      <div
        v-for="tab in tabs"
        :key="tab.name"
        @click="handleClickTab(tab.name)"
        :class="tab.name === activeTab ? 'tab active' : 'tab'"
      >
        <span class="text-tab">{{ tab.label }}</span>
      </div>
    </div>
    <div class="card shadow-sm" style="margin-top: -3px;">
      <div v-for="tab in tabs" :key="tab.name" :class="{ 'd-none': tab.name !== activeTab }">
        <component :is="tab.component" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import CardapioEdit from "@/views/cardapio/components/editCardapio/CardapioEdit";
import Pneu from "@/views/cardapio/components/Pneu";

const tabs = ref([
  { name: 'cardapio', label: 'Cardapio', component: CardapioEdit },
  { name: 'pneu', label: 'Pneu', component: Pneu },
]);

const activeTab = ref('cardapio');

const handleClickTab = (tabName) => {
  activeTab.value = tabName;
};
</script>

<style>
.tab {
  padding: 10px 20px;
  border-radius: 12px 12px 0px 0px;
  cursor: pointer;
}

.tab.active {
  background: #ffffff;
}
</style>
